import qs from 'qs';
import axios from '@/plugins/axios';
import store from '@/stores/store';
import utils from '@/stores/utils';
import documents from './submodules/documents';

const state = {
    provider: null,
    provider_loading: false,
    provider_loading_errors: null,
    provider_saving: false,
    provider_saving_errors: {},

    stats: null,

    receipts: [],
    receipts_count: 0,
    receipts_filters: { limit: 20, offset: 0, ordering: '-receipt_date' },
    receipts_loaded: false,
    receipts_loading: false,

    productprices: [],
    productprices_count: 0,
    productprices_filters: { offset: 0, limit: 20, },
    productprices_loaded: false,
    productprices_loading: false,
    productprices_loading_errors: null,
};

const mutations = {

    updateProvider(state, provider) {
        if (state.provider && provider && state.provider.id == provider.id) {
            state.provider = provider;
            return;
        }
        state.provider = provider;

        state.stats = null;
        state.receipts = [];
        state.receipts_count = 0;
        state.receipts_loaded = false;
        state.receipts_loading = false;
        state.receipts_loading_errors = null;

        state.productprices = [];
        state.productprices_count = 0;
        state.productprices_loaded = false;
        state.productprices_loading = false;
        state.productprices_loading_errors = null;

        if (!provider) {
            state.receipts_filters = { offset: 0, limit: state.receipts_filters.limit };
            state.productprices_filters = { offset: 0, limit: state.productprices_filters.limit };
            return;
        }

        state.receipts_filters = { provider: provider.id, offset: 0, limit: state.receipts_filters.limit, ordering: '-receipt_date' };
        state.productprices_filters = { provider: provider.id, offset: 0, include_archived_providers: provider.archived, limit: state.productprices_filters.limit, ordering: 'name' };
    },

    updateReceipts(state, receipts) {
        state.receipts = receipts;
    },
    updateReceiptsCount(state, count) {
        state.receipts_count = count;
    },
    updateReceiptsFilters(state, filters) {
        state.receipts_filters = Object.assign(filters || {}, { provider: state.provider.id });
    },

    updateReceiptsLoading(state, loading) {
        state.receipts_loading = loading;
    },
    updateReceiptsLoadingErrors(state, errors) {
        state.receipts_loading_errors = errors;
    },

    updateProviderLoading(state, loading) {
        state.provider_loading = loading;
    },
    updateProviderLoadingErrors(state, errors) {
        state.provider_loading = false;
        state.provider_loading_errors = errors;
    },

    updateProviderSaving(state, saving) {
        state.provider_saving = saving;
    },
    updateProviderSavingErrors(state, errors) {
        state.provider_saving = false;
        state.provider_saving_errors = errors;
    },

    updateProviderDeleting(state, deleting) {
        state.provider_deleting = deleting;
    },

    updateProductPrices(state, productprices) {
        state.productprices = productprices;
    },
    updateProductPricesFilters(state, productprices_filters) {
        state.productprices_filters = Object.assign(
            { provider: state.provider.id },
            productprices_filters
        );
    },
    updateProductPricesCount(state, productprices_count) {
        state.productprices_count = productprices_count;
    },
    updateProductPricesLoading(state, loading) {
        state.productprices_loading = loading;
    },
    updateProductPricesLoadingErrors(state, errors) {
        state.productprices_loading_errors = errors;
    },

    updateStats(state, stats) {
        state.stats = stats;
    },
};

const actions = {
    exportToCSV({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        let filters = {
            provider: state.provider.id,
            format: 'csv',
            limit: 1000,
        };
        const queryparams = qs.stringify(filters, { arrayFormat: 'repeat' });
        window.open(`/api/productprices/?${queryparams}`, '_blank');
    },
    fetchProductPrices({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        if (store.getters['session/current_user_permissions'].indexOf("core.view_productprice") == -1) {
            return;
        }

        commit('updateProductPricesLoading', true);
        commit('updateProductPricesLoadingErrors', null);

        return new Promise((resolve, reject) => {
            axios.get('/api/productprices/', {
                params: state.productprices_filters,
            })
                .then((response) => {
                    commit('updateProductPrices', response.data.results);
                    commit('updateProductPricesCount', response.data.count);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    let error = utils.handleError(xhr_error);
                    commit('updateProductPricesLoadingErrors', error.details);
                    reject(error);
                })
                .finally(() => {
                    commit('updateProductPricesLoading', false);
                })
        });
    },



    fetchReceipts({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateReceiptsLoading', true);
        commit('updateReceiptsLoadingErrors', null);

        return new Promise((resolve, reject) => {
            axios.get('/api/receipts/', {
                params: state.receipts_filters,
            })
                .then((response) => {
                    commit('updateReceiptsLoading', false);

                    commit('updateReceipts', response.data.results);
                    commit('updateReceiptsCount', response.data.count);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    let error = utils.handleError(xhr_error);
                    commit('updateReceiptsLoading', false);
                    commit('updateReceiptsLoadingErrors', error.details);
                    reject(error);
                });
        });
    },

    fetchProvider({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateProviderLoading', true);
        commit('updateProviderLoadingErrors', null);

        let url;
        if (params && params.provider_id) {
            url = `/api/providers/${params.provider_id}/`;
        } else if (state.provider) {
            url = `/api/providers/${state.provider.id}/`;
        } else {
            throw 'No provider to fetch';
        }

        return new Promise((resolve, reject) => {

            axios.get(url)
                .then((response) => {
                    commit('updateProvider', response.data);
                    dispatch('fetchStats');

                    dispatch('documents/fetchDocuments');
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateProviderLoadingErrors', error.details);
                    reject(error);
                })
                .finally(() => {
                    commit('updateProviderLoading', false);
                })
        })
    },

    deleteProvider({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateProviderDeleting', true);

        const url = `/api/providers/${params.instance.id}/`;
        return new Promise((resolve, reject) => {
            axios.delete(url)
                .then((response) => {
                    commit('updateProviderDeleting', false);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    commit('updateProviderDeleting', false);
                    reject(utils.handleError(xhr_error));
                })
                .finally(() => {
                    dispatch('session/fetchStats', null, { root: true });
                });
        })
    },

    saveProvider({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateProviderSaving', true);

        let url = '/api/providers/';
        let method = axios.post;

        if (params.instance.id) {
            url = `/api/providers/${params.instance.id}/`;
            method = axios.put;
        }
        return new Promise((resolve, reject) => {

            method(url, params.instance)
                .then((response) => {
                    commit('updateProviderSaving', false);
                    resolve(response)
                })
                .catch((xhr_error) => {
                    let errors = utils.handleError(xhr_error);
                    commit('updateProviderSavingErrors', errors.details);
                    reject(errors);
                })
                .finally(() => {
                    dispatch('session/fetchStats', null, { root: true });
                });
        });
    },

    fetchStats({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateStats', null);
        if (!state.provider) {
            return;
        }
        axios.get(`/api/providers/${state.provider.id}/stats/`)
            .then((response) => {
                commit('updateStats', response.data);
            })
    },

    init({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateProvider', params.provider);
        dispatch('fetchStats');
        if (!params.provider) {
            return;
        }
        dispatch('documents/init', {
            filterTag: `provider:${params.provider.id}`,
            createTags: [
                `provider:${params.provider.id}`,
            ]
        });
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    modules: {
        documents: documents(),
    }
};
