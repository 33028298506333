var render = function render(){
  var _vm$content, _vm$content$fields, _vm$content2, _vm$content2$fields;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.content ? [_c('div', {
    ref: "contentContainer",
    staticClass: "content-container",
    "class": {
      expanded: _vm.isExpanded
    }
  }, [_vm.showToggle ? _c('div', {
    staticClass: "fade-overlay"
  }) : _vm._e(), _c('div', {
    staticClass: "text-h6 font-weight-bold mb-4"
  }, [_vm._v(" " + _vm._s((_vm$content = _vm.content) === null || _vm$content === void 0 ? void 0 : (_vm$content$fields = _vm$content.fields) === null || _vm$content$fields === void 0 ? void 0 : _vm$content$fields.title) + " ")]), _c('ContentfulRichTextRenderer', {
    attrs: {
      "document": (_vm$content2 = _vm.content) === null || _vm$content2 === void 0 ? void 0 : (_vm$content2$fields = _vm$content2.fields) === null || _vm$content2$fields === void 0 ? void 0 : _vm$content2$fields.helpText
    }
  })], 1), _vm.showToggle ? _c('v-btn', {
    staticClass: "mt-2",
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.toggleExpand
    }
  }, [_vm._v(" " + _vm._s(_vm.$translate("seeMore")) + " ")]) : _vm._e()] : _vm.error ? _c('div', {
    key: "error"
  }, [_vm._v(_vm._s(_vm.error))]) : _vm.loading ? _c('div', {
    key: "loading"
  }, [_c('Loading', {
    attrs: {
      "absolute": false,
      "size": 50
    }
  })], 1) : _c('div', {
    directives: [{
      name: "translate",
      rawName: "v-translate"
    }],
    key: "notfound"
  }, [_c('i', [_vm._v("Content not found.")])])], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }